<script setup lang="ts">
  import { useSwipe } from '@vueuse/core'
  const elm = ref(null)

  interface Tab {
    title: string
    slug: string
    [key: string]: any
  }

  const props = defineProps<{
    modelValue?: string
    tabs: Tab[]
  }>()

  const emit = defineEmits(['update:modelValue'])

  const currentSlug = computed(() => {
    return props.modelValue
      ? props.modelValue
      : props.tabs?.[0]?.slug
  })

  const currentTabIndex = computed(() => {
    return props.modelValue
      ? props.tabs.findIndex(tab => tab.slug === props.modelValue)
      : 0
  })

  useSwipe(elm, {
    onSwipeEnd(e: TouchEvent, direction) {
      if (direction === 'RIGHT') {
        const { slug } = props.tabs?.[currentTabIndex.value - 1] || props.tabs[0]
        emit('update:modelValue', slug)
      }

      if (direction === 'LEFT') {
        const  { slug } = props.tabs?.[currentTabIndex.value + 1] || props.tabs[props.tabs.length]
        emit('update:modelValue', slug)
      }
    },
  })
</script>

<template>
  <div class="app-tabs" ref="elm">
    <nav class="app-tabs__nav" :style="{ gridTemplateColumns: `repeat(${tabs.length}, 1fr)` }">
      <button
        v-for="tab in tabs"
        :class="['app-tabs__tab', { 'app-tabs__tab--active': tab.slug === currentSlug }]"
        type="button"
        @click="$emit('update:modelValue', tab.slug)">
        {{ tab.title }}
      </button>
    </nav>

    <article
      v-for="tab in tabs.filter((tab) => tab.slug === currentSlug)"
      :id="tab.slug"
      class="app-tabs__tab-panel">
      <slot :tab="tab"/>
    </article>
  </div>
</template>

<style scoped>
  .app-tabs__nav {
    padding-bottom: var(--gap-400);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--gap-400);
  }

  .app-tabs__tab {
    display: flex;
    padding: calc(var(--gap-400) - var(--gap-100)) 0;
    border-bottom: solid var(--gap-50) currentColor;
    color: var(--c-text);
    text-decoration: none;
    line-height: 1;
  }

  .app-tabs__tab--active {
    color: var(--c-primary);
  }
</style>
